// If you don't want to use TypeScript you can delete this file!
import React from "react"
import {PageProps, Link, graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Address from "../components/address"
import {MailIcon, OfficeBuildingIcon, PhoneIcon, UserIcon} from "@heroicons/react/outline";

type DataProps = {
    site: {
        buildTime: string
    }
}

const Impress: React.FC<PageProps<DataProps>> = ({data, path}) => (
    <Layout>
        <SEO title="Impressum"/>
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">

                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
              Pflichtangaben
            </span>
                        <span
                            className="mt-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Impressum
            </span>
                    </h1>
                        <span className="mt-8 block text-1xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
    Haftungsausschluss – Disclaimer</span>

                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold"> </span> <br/>
                        Die KPF.NRW ist eine zentrale Serviceeinrichtung, die in Kooperation mit dem nordrhein-westfälischen 
                        Ministerium für Kultur und Wissenschaft sowie den Landschaftsverbänden Rheinland und Westfalen-Lippe 
                        die Forschungsaktivitäten in Nordrhein-Westfalen rund um das Thema der Herkunftserforschung bündelt.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold"> Zuständige Aufsichtsbehörde</span> <br/>
                        Ministerium für Heimat, Kommunales, Bau und Gleichstellung <br/>
                        des Landes Nordrhein-Westfalen<br/>
                        Jürgensplatz 1<br/>
                        40219 Düsseldorf

                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Verantwortlich im Sinne des § 18 Medienstaatsvertrag (MStV)</span> <br/>
                        Jasmin Hartmann<br/>
                        Tel. 0228-2070-160<br/>
                        Fax 0228-2070-299<br/>
                        E-Mail kontakt@kpf.nrw

                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Verantwortlich im LVR-Dezernat Kultur und Umwelt</span> <br/>
                        Dezernentin Dr. Corinna Franz<br/>
                        Tel. 0221 809-3785<br/>
                        Fax 0221 8284-1901<br/>
                        E-Mail kultur@lvr.de

                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Haben Sie technische Fragen zur Funktion der Website? Dann schicken Sie 
                        bitte eine Mail an: support@kpf.nrw 
                    </p>

                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Haftungshinweise</span> <br/>
                        Die Informationen des Online-Angebotes werden ständig aktualisiert. Trotz sorgfältiger 
                        Bearbeitung können sich Daten verändert haben oder Fehler aufgetreten sein. 
                        Hierfür bitten wir um Verständnis. Bitte benachrichtigen Sie uns, wenn unsere eigenen 
                        Inhalte eventuell nicht völlig fehlerfrei und vollständig sind.

                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">1. Haftung im Sinne § 7 TMG</span> <br/>
                        Die KPF.NRW stellt ihr Internetangebot unter folgenden Nutzungsbedingungen zur Verfügung:
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        1.1. Die KPF.NRW ist Diensteanbieter nach § 7 Abs. 1 TMG für die eigenen Inhalte, die sie 
                        zur Nutzung bereithält, nach den allgemeinen Vorschriften verantwortlich. Die Haftung für 
                        Schäden materieller oder ideeller Art, die durch die Nutzung der Inhalte verursacht 
                        wurden, ist ausgeschlossen, sofern nicht Vorsatz oder grobe Fahrlässigkeit vorliegt.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                    1.2. Soweit ein Text von dritter Stelle erstellt ist, wird der jeweilige Verfasser namentlich 
                    genannt. In diesen Fällen ist der Verfasser des jeweiligen Dokuments bzw. sein Auftraggeber für 
                    den Inhalt verantwortlich.
                    </p>

                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">2. Haftungsausschluss im Sinne § 8 TMG – Disclaimer</span> <br/>
                        Die KPF.NRW macht sich den Inhalt der per Hyperlinks zugänglich gemachten fremden Websites 
                        ausdrücklich nicht zu eigen und kann deshalb für deren inhaltliche Korrektheit, 
                        Vollständigkeit und Verfügbarkeit keine Gewähr leisten. Die KPF.NRW hat keinen Einfluss auf 
                        die aktuelle und zukünftige Gestaltung auf Inhalte der gelinkten Seiten und distanziert sich 
                        ausdrücklich davon. Sofern die KPF.NRW feststellt oder davon Kenntnis erhält, dass sich auf 
                        den verlinkten Websites zwischenzeitlich rechtswidrige oder mit dem Leitbild der KPF.NRW 
                        unvereinbare Inhalte befinden, werden die entsprechenden Links unverzüglich entfernt.
                    </p>
                  
                        <h1>
            <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
              &nbsp;
            </span>
                        <span
                            className="mt-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Urheberrecht
            </span> <br/>
                    </h1>
                        Die Inhalte der Internetseiten der KPF.NRW sind urheberrechtlich geschützt. Dies gilt 
                        insbesondere für Fotos bzw. Grafiken und Logos, Videodateien sowie für Texte und Daten. 
                        Eine Wiedergabe in anderen Medien bedarf der vorherigen schriftlichen Zustimmung der KPF.NRW.
                    
                    
                    <h1>
            <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
              &nbsp;
            </span>
                        <span
                            className="mt-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Bildnachweise
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Startseite:</span> <br/>
                        Jan van der Meer van Haarlem, Landschaft mit zerfallener Befestigung, 17. Jh., 1940. <br/>
                        Bildcredits: Jürgen Vogel, LVR-LandesMuseum Bonn
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Seite Über uns:</span><br/>
                        Sketch Beiratssitzung.<br/>
                        Bildcredits: ©Birgit Jansen für bikablo.com
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Seite Gremien:</span><br/>
                        Kuratorium und Beirat.<br/>
                        Bildcredits: Foto: LVR-ZMB, Annette Hiller  
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Mitarbeiterinnenportraits Jasmin Hartmann, Miriam Cockx, Dagmar Thesing und Team </span><br/>
                        Bildcredits: Jürgen Vogel, LVR-LandesMuseum Bonn
                    </p>
                    <span
                        className="mt-8 block text-1xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">Konzept & Realisierung </span>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Fafalter GmbH, Talstr. 116, 40217 Düsseldorf
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Werkzeuge</span>
                        <ul className="list-disc">
                            <li>gatsbyjs https://www.gatsbyjs.com</li>
                            <li>tailwindcss https://tailwindcss.com</li>
                            <li>tailwindui http://tailwindui.com</li>
                            <li>gitlab https://about.gitlab.com</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default Impress

export const query = graphql`
    {
        site
        {
            buildTime(formatString
            :
            "YYYY-MM-DD hh:mm a z"
            )
        }
    }
`
